import request from '@/utils/request'
import { getToken } from "@/utils/auth"

//合包任务记录删除
export function delTms_pack_merge(query) {
  return request({
    url: `/tms/tms_pack_merge/${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'delete'
  })
}

//合包任务获取明细数据
export function getMergeTaskByPackMergeId(id) {
  return request({
    url: '/tms/operaCenter/extend/merge_task_record/getMergeTaskByPackMergeId/' + id,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
  })
}

// 占位数据
export function listBank(query) {
  return request({
    url: '/tms/operaCenter/macQuery',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}

// 手工过机  scanner
export function handMachineScan(data) {
  return request({
    url: '/tms/operaCenter/handMachine',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
// 过机查询 list
export function transitMachineQuery(query) {
  return request({
    url: '/tms/operaCenter/macQuery',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}

// 签收QC  scanner
export function qCScan(data) {
  return request({
    url: '/tms/operaCenter/scanQC',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}

export function allList(query) {
  return request({
    url: '/tms/operaCenter/listQCByExpressId',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}
// 签收QC(复制)  newPackSub_copy
export function newPackSub_copy(data) {
  return request({
    url: '/tms/operaCenter/newPackSub_copyNoInSert',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
// 签收QC  newPackSub
export function newPackSub(data) {
  return request({
    url: '/tms/operaCenter/newPackSub',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
// 签收QC  delPackSub
export function delPackSub(data) {
  return request({
    url: '/tms/operaCenter/delPackSub',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
// 签收QC >VCB  uptPackSub
export function uptPackSub(data) {
  return request({
    url: '/tms/operaCenter/uptPackSub',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
// 签收QC >集运  uptPackSub
export function qcUptPackSub(data) {
  return request({
    url: '/tms/operaCenter/qcUptPackSub',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
// 签收QC VCB selPackSub
export function selPackSub(data) {
  return request({
    url: '/tms/operaCenter/selPackSub',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
// 签收QC 列表
export function listQC(data) {
  return request({
    url: '/tms/operaCenter/listQC',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: data
  })
}
// 入库 列表
export function inventory(data) {
  return request({
    url: '/tms/operaCenter/inventory',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: data
  })
}
// 入库 列表
export function inventoryNoPage(data) {
  return request({
    url: '/tms/operaCenter/inventoryNoPage',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: data
  })
}

// 集包 列表
export function setPackage(data) {
  return request({
    // url: '/tms/operaCenter/extend/app/check_cds',
    url: '/tms/operaCenter/setPackage',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: data
  })
}
// 集包扫描 inputScanData  inputScanDataPrint
// PC集包  2022年9月3日13:55:46  wjy
export function scanDataF5(data) {
  return request({
    url: '/tms/operaCenter/inputScanData',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
//合包页面调整接口
export function inputScanDataList(data) {
  return request({
    url: '/tms/operaCenter/getPTmsMerge/' + data,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

//原合包页面接口
export function inputScanData(data) {
  return request({
    url: '/tms/Scan/putScanData',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
// 合包操作
export function getPackidList(data) {
  return request({
    url: '/tms/operaCenter/getPGetPackid/' + data,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',

  })
}
// 存储过程  参数13
export function postScanData(data) {
  return request({
    url: '/tms/operaCenter/inputScanData',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}

// 集包扫描 inputScanDataPrint
export function inputScanDataPrint(data) {
  return request({
    url: '/tms/operaCenter/inputScanDataPrint',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
// 合包并打印
export function getScanPrint(data) {
  return request({
    url: '/tms/operaCenter/packageAndPrint/' + data,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
  })
}

// 集包 获取包号 getBoxCode
export function getBoxCode(data) {
  return request({
    url: '/tms/operaCenter/getBoxCode',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: data
  })
}
//  拼袋扫描 >列表
export function bagScan(q) {
  return request({
    url: '/tms/operaCenter/bagScan',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: q
  })
}
// 封袋称重
export function sealWeigh(query) {
  return request({
    url: '/tms/operaCenter/sealWeigh',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}
// 装袋记录
export function bagRecords(query) {
  return request({
    url: '/tms/operaCenter/bagRecords',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}
// 装袋记录 >行删除操作
export function delBoxMx(query) {
  return request({
    url: `/tms/operaCenter/delBoxMx/${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'delete'
  })
}

// 装袋记录明细
export function bagRecordDetails(query) {
  return request({
    url: `/tms/operaCenter/bagRecordDetails/${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
// 出库单
export function outOrderList(query) {
  return request({
    url: `/tms/operaCenter/outOrderList`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}
// 推送vcb
export function pushVCB(data) {
  return request({
    url: `/tms/operaCenter/send_to_e3`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}

// 出库单 装袋记录
export function baggingRecords(query) {
  return request({
    url: `/tms/operaCenter/baggingRecords/${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
// 出库单 删除装袋记录
export function delBaggingRecord(query) {
  return request({
    url: `/tms/operaCenter/baggingRecords/${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'delete'
  })
}
// 出库单 包内明细
export function bagDetail(query) {
  return request({
    url: `/tms/operaCenter/bagDetail/${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
// 无主列表
export function listNotOwner(query) {
  return request({
    url: `/tms/operaCenter/listNotOwner`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}
export function wzNew(data) {
  return request({
    url: '/tms/operaCenter/wzNew',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
// 无主处理 wzNew前 判断单号是否已存在
export function checkExpress(query) {
  return request({
    url: `/tms/operaCenter/wz/check_express/${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
// 无主处理 绑定前 判断po是否已存在
export function checkPO(query) {
  return request({
    url: `/tms/operaCenter/wz/check_order/${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
// 无主 vcb 入库单列表
export function listReceipt(query) {
  return request({
    url: `/tms/operaCenter/wz/${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

export function wzExpress(data) {
  return request({
    url: '/tms/operaCenter/wzExpress',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
export function wzDel(data) {
  return request({
    url: '/tms/operaCenter/wzDel',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
export function wzPut(data) {
  return request({
    url: '/tms/operaCenter/wzPut',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
export function wzSel(data) {
  return request({
    url: '/tms/operaCenter/wzSel',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
export function wzRegister(data) {
  return request({
    url: '/tms/operaCenter/wz_Register',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}

// 包装费
export function packageFee(query) {
  return request({
    url: `/tms/operaCenter/packageFee`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}
// 包装费 扫描
export function feePackSel(data) {
  return request({
    url: '/tms/operaCenter/feePackSel',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
// 包装费 提交
export function feePackPut(query) {
  console.log('参数参数', query)
  return request({
    url: `/tms/operaCenter/fee/feePackPut`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}

/**
 * 扩展 -------------
 */

// 拦截
export function interceptList(query) {
  return request({
    url: `/tms/operaCenter/extend/intercept/list`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}
export function addIntercept(query) {
  return request({
    url: `/tms/operaCenter/extend/intercept`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    params: query
  })
}
// 介入单 列表
export function interveneList(query) {
  return request({
    url: `/tms/operaCenter/extend/intervene/list`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}
export function delIntervene(query) {
  return request({
    url: `/tms/operaCenter/extend/intervene/del/${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'delete'
  })
}
// 扫描查询
export function scanQueryList(query) {
  return request({
    url: `/tms/operaCenter/extend/scanQuery/list`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}
// 获取站点
export function getSite() {
  return request({
    url: `/tms/operaCenter/extend/scanQuery/site`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
// 获取扫描类型
export function getScanType() {
  return request({
    url: `/tms/operaCenter/extend/scanQuery/type`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
// generateBarcode
export function pTmsGetId() {
  return request({
    url: `/tms/operaCenter/extend/generateBarcode/pTmsGetId`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
// generateBarcode print
export function pTmsGetIdPrint(data) {
  return request({
    url: `/tms/operaCenter/extend/generateBarcode/print`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
// 合包任务分配 列表
export function listMergeTask(query) {
  return request({
    url: `/tms/operaCenter/extend/merge_task/list`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}
export function mergeTaskDetail(query) {
  return request({
    url: `/tms/operaCenter/extend/merge_task/detail?packMergeId=${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
// 生成合包 确认
export function uptMergeUser(data) {
  return request({
    url: `/tms/operaCenter/extend/merge_task`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'put',
    data: data
  })
}
// 生成合包 打印
export function mergeTaskPrint(query) {
  return request({
    url: `/tms/operaCenter/extend/merge_task/print?packMergeId=${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
// 员工列表
export function mergeTaskStaffs() {
  return request({
    url: `/tms/operaCenter/extend/merge_task/staffs`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

// P_TRACK_NEW
export function pTrackNew(data) {
  return request({
    url: `/tms/operation/transport/p_track_new`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
// 入库单
export function inOrderList(query) {
  return request({
    url: `/tms/operation/in_order/list`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}
// 入库单 装袋记录
export function inBaggingRecords(query) {
  return request({
    url: `/tms/operation/in_order/bagging_records?boxMainId=${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
// 入库单
// 袋内包明细
export function inInnerBagDetails(query) {
  return request({
    url: `/tms/operation/in_order/innerBagDetails?boxCode=${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

// 移出袋扫描 列表
export function removeBagScanList(query) {
  return request({
    url: `/tms/operaCenter/extend/removeBagScanList?receiptId=${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
// 取消派送任务
export function cancelTask(data) {
  return request({
    url: `/tms/delivery_task/allocation/cancel?orderSignId=${data}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'put'
  })
}
// 派送详情
export function deliveryDetail(query) {
  return request({
    url: `/tms/delivery_task/detail/${query}`,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

/** 扫描上传 */
export function scanUpload(data) {
  return request({
    url: '/tms/Scan/putScanData',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}

/** 查询拒收类型 */
export function problem(lang) {
  return request({
    url: '/tms/term/categoryDetail/' + lang + '/REJECTION_TYPE',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

/** 查询问题类型 */
export function problemType(lang) {
  return request({
    url: '/tms/term/categoryDetail/' + lang + '/PROBLEM_TYPE',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

/** 查询下一站 */
export function nextStand(lang) {
  return request({
    url: '/system/SysSite/selectList?langId=' + lang,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

// 库存明细 打印
export function invrntoryPrint(data) {
  return request({
    url: '/tms/operaCenter/print',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: data
  })
}

// 库存明细 备注
export function invrntoryRemark(data) {
  return request({
    url: '/tms/operaCenter/remark',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: data
  })
}
// 库存明细 备注更新
export function updateInvrntoryRemark(data) {
  return request({
    url: '/tms/operaCenter/updateRemark',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: data
  })
}

// 库存明细 订单确认
export function invrntoryWarehousingConFirm(data) {
  return request({
    url: '/tms/operaCenter/warehousingConFirm',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: data
  })
}
// 库存明细 订单确认 更新
export function updateInvrntoryWarehousingConFirm(data) {
  return request({
    url: '/tms/operaCenter/updateWarehousingConFirm',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: data
  })
}



// 库存明细 详情
export function detailUpdate(data) {
  return request({
    url: '/tms/operaCenter/detailUpdate',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: data
  })
}
// 库存明细 详情修改
export function updateDetailUpdate(data) {
  return request({
    url: '/tms/operaCenter/updatedetailUpdate',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: data
  })
}

// 出库单 删除
export function outOrderDel(data) {
  return request({
    url: '/tms/operaCenter/outOrderDel',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: data
  })
}

// 出库单 删除
export function putAway(data) {
  return request({
    url: '/tms/operaCenter/handMachine_old',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
//p结果显示
export function logisTics(data) {
  return request({
    url: '/tms/operaCenter/extend/incomingScan/'+data,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
  })
}
//显示附加费录入
export function editPackSubList(receiptId) {
  return request({
    url: '/tms/operaCenter/editPackSub/'+receiptId,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
//显示附加费录入
export function addandupdateTmsPackSubFee(data) {
  return request({
    url: '/tms/operaCenter/addandupdateTmsPackSubFee',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}



//显示附加费录入
export function delTmsPackSubFee(packSubFeeId) {
  return request({
    url: '/tms/operaCenter/delTmsPackSubFee/'+packSubFeeId,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

// 校验快递单号是否已经入库
export function checkExpressId(expressId) {
  return request({
    url: '/tms/operaCenter/checkExpressId/' + expressId,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

// 校验客户代号是否存在
export function checkCustomerId(customerId) {
  return request({
    url: '/tms/operaCenter/checkCustomerId/' + customerId,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

// 简易入库，调用存储过程P_TMS_QC_Express
export function erpSimpleInWarehouse(data) {
  return request({
    url: '/tms/operaCenter/erpSimpleInWarehouse',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}

// 获取货品名称
export function getGoodstypeAndGoods() {
  return request({
    url: '/tms/Order/getGoodstypeAndGoods',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

/** 获取货品类型和名称 */
export function getGoodTypeAndName() {
  return request({
    url: '/tms/Order/getGoodTypeAndName',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

/** 获取中国收货地址 */
export function receAddList(query) {
  return request({
    url: '/tms/address/list',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}

/** 查询客户资料收货地址 */
export function cusAddressList(query) {
  return request({
    url: '/tms/cusAddress/list',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}

/** 查询货物品名列表 */
export function listGoods(query) {
  return request({
    url: '/tms/goods/list',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}

export function exportData(data) {
  return request({
    url: '/tms/operaCenter/exportData',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}
